
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "workday",
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Parte Diario", ["Listado"]);
      MenuComponent.reinitialization();
    });
  },
});
